<template>
  <div
    id="app"
    :key="$i18next.language"
    :class="[{ mobile: isMobile }]"
  >
    <DesktopRedirect v-if="showRedirectedToAppOverlay" />
    <keep-alive
      v-else
      :include="cachedComponents"
    >
      <router-view />
    </keep-alive>
    <Onboarding
      v-if="showOnboarding"
      @closeOnboarding="setShowOnboarding(false)"
    />
    <PortalTarget name="root-widget"></PortalTarget>
    <QuickCard
      v-if="quickCardIsOn"
      :current-card-id="quickCardCurrentEpicId"
    />
    <QuickPage v-if="quickPageIsOn" />
    <EmojiPopover />
    <UpgradePlanPopUp v-if="upgradePlanPopUpIsDisplayed" />
    <AddMember v-if="showAddMemberPopup" />
    <DeleteResource v-if="showDeleteResourcePopup" />
    <ElectronFindInPageUnderlay v-if="isElectronApp && showElectronFindInPageUnderlay" />
    <SprintEditModal v-if="showSprintEditModal" />
    <div
      v-if="showElectronExtras"
      class="electron-extras-wrap"
      :class="{
        'top-[48px]': showPeekSidebar,
      }"
    >
      <ElectronExtras />
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { ObserveVisibility } from 'vue-observe-visibility';
import VTooltip from 'v-tooltip';
import VueMeta from 'vue-meta';
import vuescroll from 'vuescroll/dist/vuescroll-native';
import vuescrollOptions from '@/utilities/vuescrollOptions';
import autofocus from '@/utilities/autofocus.directive';
import constants from '@/utilities/constants';
import capitalizeFirstLetter from '@/utilities/capitalizeFirstLetter';
import update from '@/mixins/update';
import draggingIsOn from '@/mixins/draggingIsOn';
import breakpoints from '@/plugins/BreakpointsPlugin';
import { setRootStyle, getMobileSidebarWidth } from '@/utilities/rootStyles';
import themeMixin from '@/mixins/themeMixin';
import { PortalTarget } from 'portal-vue';
import Onboarding from '@/components/pages/Onboarding.vue';
import electronOpenInAppMixin from '@/mixins/electronOpenInAppMixin';
import DesktopRedirect from '@/components/pages/authentication/DesktopRedirect.vue';
import vuedraggable from 'vuedraggable';
import { MessageTypes } from '@/types/messageTypes';
import iconTypeMixin from '@/mixins/iconTypeMixin';
import useOnboardingActions from '@/utilities/composables/useOnboardingActions';
import isGuestRoleMixin from '@/mixins/isGuestRoleMixin';
import isBoardFullyLoaded from '@/utilities/isBoardFullyLoaded';
import { BroadcastChannel } from 'broadcast-channel';
import '@/webcomponents';
import recentlyViewedMixin from '@/mixins/recentlyViewed';
import electronHelpersMixin from '@/mixins/electronHelpersMixin';
import ElectronFindInPageUnderlay from '@/components/widgets/ElectronFindInPageUnderlay.vue';
import viewRouteCheckMixin from '@/mixins/viewRouteCheckMixin';
import ElectronExtras from '../widgets/ElectronExtras.vue';
import EventBus from '../../utilities/eventBus';
import AddMember from '@/components/widgets/AddMember.vue';
import DeleteResource from '@/components/widgets/DeleteResource';
import draghover from '@/utilities/draghover.directive';
import SocketService from '@/utilities/SocketService';
import useShortcutHandlers from '@/utilities/composables/useShortcutHandlers';
import vtippy from '@/utilities/vtippy.directive';
import hideDropdownsMixin from '@/mixins/hideDropdownsMixin';
import isEqual from 'lodash/isEqual';

// eslint-disable-next-line vue/component-definition-name-casing
Vue.component('draggable', vuedraggable);

Vue.use(VTooltip, {
  defaultHtml: false,
  popover: {
    disposeTimeout: 10,
    defaultPopperOptions: {
      modifiers: {
        computeStyle: {
          gpuAcceleration: false,
        },
      },
    },
  },
});

Vue.directive('observe-visibility', ObserveVisibility);
Vue.directive('autofocus', autofocus);
Vue.directive('draghover', draghover);
Vue.directive('tippy', vtippy);
Vue.use(VueMeta);
// Global variables
Vue.prototype.$constants = constants;
Vue.prototype.$cons = constants;
Vue.prototype.$capitalizeFirstLetter = capitalizeFirstLetter;
Vue.prototype.$isBoardFullyLoaded = isBoardFullyLoaded;
Vue.prototype.$messageTypes = MessageTypes;
Vue.use(vuescroll, vuescrollOptions);
Vue.mixin(draggingIsOn);
Vue.use(breakpoints);
Vue.mixin(themeMixin);
Vue.mixin(iconTypeMixin);
Vue.mixin(isGuestRoleMixin);
Vue.mixin(viewRouteCheckMixin);

const channel = new BroadcastChannel('st-sw-messages');
const prefersColorSchemeDark = '(prefers-color-scheme: dark)';

export default {
  components: {
    PortalTarget,
    Onboarding,
    DesktopRedirect,
    EmojiPopover: () =>
      import(/* webpackChunkName: "projectModals" */ '@/components/widgets/emojis/EmojiPopover'),
    QuickCard: () => import(/* webpackChunkName: "quickCard" */ '@/components/elements/QuickCard'),
    QuickPage: () =>
      import(/* webpackChunkName: "projectModals" */ '@/components/elements/QuickPage'),
    ElectronFindInPageUnderlay,
    ElectronExtras,
    AddMember,
    DeleteResource,
    SprintEditModal: () =>
      import(/* webpackChunkName: "projectModals" */ '@/components/sprints/SprintEditModal'),
    UpgradePlanPopUp: () =>
      import(/* webpackChunkName: "projectModals" */ '@/settings/components/UpgradePlanPopUp'),
  },

  mixins: [
    update,
    electronOpenInAppMixin,
    recentlyViewedMixin,
    electronHelpersMixin,
    hideDropdownsMixin,
  ],

  setup() {
    const { fetchOnboardingActions, userCreatedAfterOnboardingActionsRelease } =
      useOnboardingActions();
    const { quickCardShortcutHandler, quickPageShortcutHandler, isInput } = useShortcutHandlers();

    return {
      fetchOnboardingActions,
      userCreatedAfterOnboardingActionsRelease,
      quickCardShortcutHandler,
      quickPageShortcutHandler,
      isInput,
    };
  },

  data() {
    return {
      mode: '',
      showElectronFindInPageUnderlay: false,
      historyCardId: '',
      gsLoaded: false,
      isProjectColumnDefined: false,
      observer: null,
      dragging: false,
      teadmIdWatch: null,
    };
  },

  metaInfo() {
    return {
      title: 'Tasks & Docs. Together.',
      link: [
        {
          rel: 'icon',
          href: this.faviconPath,
        },
      ],
      lastVisitedTimestamp: null,
    };
  },
  computed: {
    showDeleteResourcePopup() {
      return this.$store.getters.getDeletePopupId;
    },
    cachedComponents() {
      return this.$store.getters.getCachedComponents;
    },
    showAddMemberPopup() {
      return this.$store.getters.getAddMemberPopupIsOn;
    },
    isWindowsOS() {
      return (
        navigator.userAgent.indexOf('Windows') !== -1 ||
        navigator.platform.includes('Win') ||
        navigator.appVersion.indexOf('Win') !== -1
      );
    },
    showElectronExtras() {
      return this.isElectronApp && !this.isSettings && this.isProjectColumnDefined;
    },
    showPeekSidebar() {
      return this.$store.getters.showPeekSidebar || false;
    },
    isAnyCardDisplayed() {
      return this.$store.getters.getIsAnyCardDisplayed;
    },
    isAnyCardDisplayedInDockedLayout() {
      return this.$store.getters.getIsAnyCardDisplayedInLayout(this.$constants.docked);
    },
    inboxResource() {
      return this.$store.getters.getCurrentInboxResource;
    },
    isInbox() {
      return this.$route.name === this.$constants.routeNames.inbox;
    },
    isSettings() {
      return this.$route.path.includes('/settings/');
    },
    faviconPath() {
      switch (process.env.VUE_APP_ENV) {
        case 'dev':
          return '/dev32.ico';
        case 'staging':
          return '/staging32.ico';
        default:
          return '/favicon.ico';
      }
    },

    networkStatus() {
      return this.$store.getters.getOnline;
    },

    healthCheckUrl() {
      return `${this.$store.getters.getRoutes.users}/v1/users/me`;
    },

    showOnboarding() {
      return this.$store.getters.getShowOnboarding;
    },

    showRedirectedToAppOverlay() {
      // disable showing as overlay when going from app to browser to singing with google then redirecting to app
      return this.$store.getters.getShowRedirectedToAppOverlay && !this.disableOpeningLinksInApp;
    },
    loggedIn() {
      return this.$store.getters.getLoggedIn;
    },
    user() {
      return this.$store.getters.getUser;
    },

    quickCardIsOn() {
      return this.$store.getters.getQuickCardIsOn;
    },

    // use for 'add new card' for epic
    quickCardCurrentEpicId() {
      return this.$store.getters.getCardInEpicCreation;
    },

    quickPageIsOn() {
      return this.$store.getters.getQuickPage;
    },

    teamId() {
      return this.$store.getters.getTeamID;
    },

    showSprintEditModal() {
      return this.$store.getters.getSprintForEditId?.id;
    },

    upgradePlanPopUpIsDisplayed() {
      return !!this.$store.getters.getShowUpgradePlanPopup;
    },

    cards() {
      return this.$store.getters.getCards;
    },
  },

  watch: {
    loggedIn(newVal, oldValue) {
      if (newVal && !oldValue) {
        const { id, email, name, profile_image, display_name } = this.user;
        // identify user in posthog
        this.$posthog.identify(id, {
          email,
          name,
        });

        // identify user in gosquared if _gs is defined
        if (this.gsLoaded) {
          window._gs('identify', {
            id,
            email,
            name,
            ...(profile_image && { avatar: profile_image }),
            ...(display_name && { username: display_name }),
          });
        }
      } else if (!newVal && oldValue) {
        // reset posthog user
        this.$posthog.reset();
        // unidentify in gosquared if _gs is defined
        if (this.gsLoaded) {
          window._gs('unidentify');
        }
      }
    },
    updateExists(newVal) {
      if (newVal) {
        const notificationLabels = [
          {
            text: this.translate('update'),
            type: this.$constants.uiNotificationElements.button,
            callback: this.refreshApp,
            buttonStyle: 'attention',
          },
          {
            text: `${this.translate('whatsNew')}?`,
            type: this.$constants.uiNotificationElements.button,
            callback: this.openChangeLog,
            buttonStyle: 'important',
            isChangeLogButton: true,
          },
        ];
        this.$store.dispatch('addUiNotification', {
          icon: 'rocket',
          message: this.translate('NewVersionAvailable'),
          duration: -1,
          dismissible: true,
          dismissCallback: this.refreshApp,
          labels: notificationLabels,
        });
      }
    },
    mode(newVal) {
      document.body.setAttribute('data-theme', newVal);
      this.$store.dispatch('setTheme', newVal);
    },
    $route: {
      handler(newVal, oldVal) {
        sessionStorage.setItem('route', newVal.path);
        this.$nextTick(() => {
          if (this.isElectronApp) {
            this.isProjectColumnDefined = !!document.getElementById(this.$constants.projectsColumn);
            if (!this.isProjectColumnDefined) return;
            this.setClassesAndAdjustColumns();
          }
        });

        if (!oldVal.name || oldVal.name === this.$constants.routeNames.workspaces) {
          this.setupOnboardingActions();
        }
      },
      deep: true,
    },

    networkStatus(newValue, oldValue) {
      if (newValue && !oldValue) {
        this.$store.dispatch('callReconnectionCallbacks');
      }
    },
  },

  beforeMount() {
    this.checkIfLocalDbExists();
  },

  mounted() {
    this.teadmIdWatch = this.$watch('teamId', () => {
      if (this.teamId) {
        this.$store.dispatch('getAllTeamFeatures');

        if (this.$socket && this.$socket.ws.readyState === 1) {
          this.$socket.ws.close();
        }

        Vue.prototype.$socket = new SocketService({
          storeDispatch: this.$store.dispatch,
          storeGetters: this.$store.getters,
          route: 'this.$router',
        });
      }
    });

    this.userCreatedCheck();

    this.$store.dispatch('setThemePreference', localStorage.themePreference || 'matchSystem');
    if (this.$store.getters.getThemePreference === 'matchSystem') {
      this.mode = window.matchMedia(prefersColorSchemeDark).matches ? 'dark' : 'light';
    } else {
      this.mode = this.$store.getters.getThemePreference;
    }
    const mediaQuery = window.matchMedia(prefersColorSchemeDark);
    if (mediaQuery?.addEventListener) {
      mediaQuery.addEventListener('change', this.handleModeChange);
    } else {
      mediaQuery.addListener(this.handleModeChange);
    }

    channel.addEventListener('message', this.handleBroadcastChannelMessage);
    window.addEventListener('offline', this.handleOfflineEvent);

    navigator.serviceWorker.ready.then(() => {
      const data = {
        url: this.healthCheckUrl,
        type: 'HEALTH_CHECK_URL',
        origin: window.location.hostname,
      };

      navigator?.serviceWorker?.controller?.postMessage(data);
    });
    window.addEventListener('beforeunload', this.beforeUnloadHandler);
    window.addEventListener('popstate', this.handlePopstate);
    window.addEventListener('keydown', this.keyDownHandler);

    this.gsLoaded = window._gs && typeof window._gs === 'function';
    document.addEventListener('visibilitychange', this.handleDocumentVisibilityChange);
    EventBus.$on('projectsColumnMounted', this.setIsProjectColumnDefined);

    this.$nextTick(() => {
      Vue.prototype.$blurhashWorker = new Worker(
        new URL(
          /* webpackChunkName: "blurHashWorker" */ '../../workers/blurhashWorker',
          import.meta.url
        ),
        { type: 'module' }
      );
      this.$blurhashWorker.onmessage = (e) => {
        const { hash, imageData, width, height } = e.data;
        EventBus.$emit(hash, { imageData, width, height });
      };
    });
  },

  destroyed() {
    const mediaQuery = window.matchMedia(prefersColorSchemeDark);
    if (mediaQuery?.removeEventListener) {
      mediaQuery.removeEventListener('change', this.handleModeChange);
    } else {
      mediaQuery.removeListener(this.handleModeChange);
    }

    channel.removeEventListener('message', this.handleBroadcastChannelMessage);
    window.removeEventListener('offline', this.handleOfflineEvent);

    if (this.isElectronApp && window.electron && window.electron.removeAllListeners) {
      window.electron.removeAllListeners('toggle-find-in-page-underlay-channel');
    }

    window.removeEventListener('beforeunload', this.beforeUnloadHandler);
    window.removeEventListener('popstate', this.handlePopstate);
    window.removeEventListener('keydown', this.keyDownHandler);

    document.removeEventListener('visibilitychange', this.handleDocumentVisibilityChange);
    EventBus.$off('projectsColumnMounted', this.setIsProjectColumnDefined);
    if (this.observer) {
      this.observer.disconnect();
    }
    this.$socket.disconnect();
    this.teadmIdWatch();
  },
  created() {
    if (this.isMobile) {
      const mvh = window.innerHeight;
      // We set the value in the --mvh custom property to the root of the document
      setRootStyle('--mvh', `${mvh}px`);
      localStorage.setItem('sidebarWidth', getMobileSidebarWidth());
      localStorage.setItem('sidebarExpandWidth', getMobileSidebarWidth());
    }

    // '-webkit-app-region: drag' set to an element causes electron BrowserView (which is above it) to not register mouse events
    // to avoid that we add an underlay below the BrowserView and set it to not be draggable
    //  * in this case BrowserView is used to display the 'find in page' bar for electron app
    if (this.isElectronApp && window.electron && window.electron.on) {
      window.electron.on('find-in-page-channel', (event, { toggleUnderlay }) => {
        if (toggleUnderlay)
          this.showElectronFindInPageUnderlay = !this.showElectronFindInPageUnderlay;
      });
    }
  },

  methods: {
    setClassesChangeObserver() {
      this.observer = new MutationObserver((mutations) => {
        for (const m of mutations) {
          const newValue = m.target.getAttribute(m.attributeName);
          this.$nextTick(() => {
            this.adjustColumns(newValue, m.oldValue);
          });
        }
      });
      this.observer.observe(document.getElementById(this.$constants.projectsColumn), {
        attributes: true,
        attributeOldValue: true,
        attributeFilter: ['class'],
      });
    },
    adjustColumns(classes) {
      const rightCol = document.querySelector('.right-side-content .sidebar-toggle-wrap');
      const divider = document.querySelector('.view-header .view-header-wrap .divider');

      if (typeof classes !== 'string') {
        return;
      }

      // 70px = width of app traffic light buttons for mac
      // move headers content to right to avoid overlapping
      if (rightCol) {
        rightCol.style.paddingLeft = classes.indexOf('expanded') === -1 ? '70px' : 0;
      }

      if (divider) {
        divider.style.marginLeft = 0;
      }
    },
    setClassesAndAdjustColumns() {
      if (!this.isSettings && !this.isWindowsOS && this.isElectronApp) {
        this.setClassesChangeObserver();
        this.adjustColumns(document.getElementById(this.$constants.projectsColumn).classList.value);
      }
    },
    setIsProjectColumnDefined() {
      if (this.isElectronApp) {
        this.isProjectColumnDefined = true;
        this.setClassesAndAdjustColumns();
      }
    },
    handlePopstate(event) {
      const cardId = event.state?.cardId;
      if (cardId && this.cards[cardId]) {
        this.$store.dispatch('setSelectedCardId', cardId);
        this.$store.dispatch('setDisplayedCards', [cardId]);
      } else if (this.historyCardId || !this.isAnyCardDisplayedInDockedLayout) {
        this.$store.dispatch('setSelectedCardId', '');
        this.$store.dispatch('removeDisplayedCardsByField', {
          field: 'layout',
          value: this.$constants.docked,
          shouldMatch: false,
        });
        this.$store.dispatch('setFavouritesOpened', false);
        this.$store.dispatch('setOpenedFavouriteItem', {});
      }
      if (this.isInbox && !this.isAnyCardDisplayed && this.inboxResource.resource_type === 'card') {
        this.$store.dispatch('setSelectedCardId', this.inboxResource.resource_id);
        this.$store.dispatch('setDisplayedCards', [this.inboxResource.resource_id]);
      } else if (
        this.isInbox &&
        !this.isAnyCardDisplayed &&
        this.inboxResource.resource_type === 'board'
      ) {
        this.$store.dispatch('setCurrentBoardId', this.inboxResource.resource_id);
      }
      this.$route.query.userNavigated = undefined;
      requestAnimationFrame(() => {
        const newPath = window.location.pathname + window.location.search;
        if (this.$route.fullPath !== newPath) {
          this.$router.replace(newPath);
        }
      });
      this.historyCardId = cardId;
      this.hideAllDropdowns();
    },
    setShowOnboarding(value) {
      this.$store.dispatch('setShowOnboarding', value);
    },
    handleModeChange(event) {
      const systemPreference = this.$store.getters.getThemePreference;
      if (systemPreference === 'matchSystem') {
        this.mode = event.matches ? 'dark' : 'light';
      }
    },
    handleOfflineEvent() {
      const data = {
        url: this.healthCheckUrl,
        type: 'HEALTH_CHECK_URL',
        origin: window.location.hostname,
      };
      if (navigator.serviceWorker.controller) {
        navigator?.serviceWorker?.controller?.postMessage(data);
      }
    },

    handleBroadcastChannelMessage(event) {
      if (event.type === 'online-status') {
        this.pushNetworkStatusNotification(event.online);
      }

      if (event.type === 'check-online-status') {
        if (!this.networkStatus) {
          this.pushNetworkStatusNotification(true);
        }
      }
    },

    pushNetworkStatusNotification(online) {
      if (online !== this.networkStatus) {
        this.$store.dispatch('setOnline', online);
        if (online) {
          this.$store.dispatch('addUiNotification', {
            message: this.translate('onlineAgain'),
            icon: 'online',
            status: this.$constants.uiNotificationStatuses.success,
            onlineStatus: true,
          });
        } else {
          this.$store.dispatch('addUiNotification', {
            message: this.translate('youAreOffline'),
            icon: 'offline',
            status: this.$constants.uiNotificationStatuses.error,
            duration: -1,
            dismissible: false,
            onlineStatus: false,
          });
        }
      }
    },

    openChangeLog() {
      window.open(this.$constants.changelogUrl, '_blank');
    },

    async checkIfLocalDbExists() {
      if (!window.indexedDB.databases) return;
      const presistedKeys = (await window.indexedDB?.databases()).map((db) => db.name);
      this.$store.dispatch('setPersistedPagesKeys', presistedKeys);
    },

    beforeUnloadHandler(event) {
      if (this.$store.getters.getAreAttachmentsUploading) {
        event.preventDefault();
        event.returnValue = '';
      }
    },

    handleDocumentVisibilityChange() {
      const currentTimestamp = Math.floor(Date.now() / 1000);
      if (document.hidden) {
        this.lastVisitedTimestamp = currentTimestamp;
      } else {
        // if the user was away for more than 30 minutes
        if (currentTimestamp - this.lastVisitedTimestamp > 30 * 60) {
          this.$store.dispatch('callReconnectionCallbacks');
          this.$socket.resubscribe();
        }
      }
    },

    setupOnboardingActions() {
      if (this.user.time_created) {
        this.fetchOnboardingActions();
        return;
      }
      // in case user is not loaded, create one time watcher
      const w = this.$watch(
        () => this.user.time_created,
        () => {
          this.fetchOnboardingActions();
          w();
        }
      );
    },

    userCreatedCheck() {
      if (this.user.time_created) {
        this.setupTimezone();
        this.setupLocale();
        return;
      }

      const w = this.$watch(
        () => this.user.time_created,
        () => {
          this.setupTimezone();
          this.setupLocale();
          w();
        }
      );
    },

    setupTimezone() {
      const timezone_id = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const userTimezoneId = this.$store.getters.getUserTimezoneId;

      if (userTimezoneId !== timezone_id) {
        this.$store.dispatch('updateUserTimezoneId', timezone_id);
      }
    },

    setupLocale() {
      this.changeLanguage(this.$store.getters.getUserLocale || navigator.language);
    },

    keyDownHandler(event) {
      if (this.isInput()) return;
      EventBus.$emit('keydown', event);
      if (event.metaKey || event.ctrlKey || event.repeat) {
        return;
      }
      const blacklistedRoutes = [
        this.$constants.routeNames.teamLogin,
        this.$constants.routeNames.login,
        this.$constants.routeNames.workspaces,
        this.$constants.routeNames.newWorkspace,
        this.$constants.routeNames.verifyEmail,
        this.$constants.routeNames.onboardingInfo,
        this.$constants.routeNames.join,
      ];
      if (blacklistedRoutes.includes(this.$route.name)) {
        return;
      }
      this.quickCardShortcutHandler(event);
      this.quickPageShortcutHandler(event);
    },
  },
};
</script>

<style lang="scss">
#app {
  height: 100vh;
  position: fixed;
  width: 100vw;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;

  > div {
    flex: 1;
  }

  > .vue-portal-target,
  .quick-card-wrap,
  .quick-page {
    flex: 0;
  }

  .electron-extras-wrap {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999;
    width: var(--sidebar-width);
  }
}

@media only screen and (max-width: 768px) {
  #app {
    height: unset;
  }
}

.mobile {
  &#app {
    > div {
      height: var(--mvh);
    }
  }
}
</style>
